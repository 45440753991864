/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobSetupStep
 */
export interface JobSetupStep {
    /**
     * 
     * @type {string}
     * @memberof JobSetupStep
     */
    stepName: JobSetupStepStepNameEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetupStep
     */
    state: JobSetupStepStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetupStep
     */
    isRelevantToJob: boolean;
}

/**
* @export
* @enum {string}
*/
export enum JobSetupStepStepNameEnum {
    OverviewState = 'setup_step_overview_state',
    JobBasicsState = 'setup_step_job_basics_state',
    JobDescriptionState = 'setup_step_job_description_state',
    InboundSourcesState = 'setup_step_inbound_sources_state',
    PitchState = 'setup_step_pitch_state',
    OutreachState = 'setup_step_outreach_state',
    ScreenerQuestionsState = 'setup_step_screener_questions_state',
    InterviewPlanState = 'setup_step_interview_plan_state',
    SearchCriteriaState = 'setup_step_search_criteria_state',
    SourcingAutopilotPendingCallState = 'setup_step_sourcing_autopilot_pending_call_state'
}/**
* @export
* @enum {string}
*/
export enum JobSetupStepStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function JobSetupStepFromJSON(json: any): JobSetupStep {
    return JobSetupStepFromJSONTyped(json, false);
}

export function JobSetupStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetupStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepName': json['step_name'],
        'state': json['state'],
        'isRelevantToJob': json['is_relevant_to_job'],
    };
}

export function JobSetupStepToJSON(value?: JobSetupStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step_name': value.stepName,
        'state': value.state,
        'is_relevant_to_job': value.isRelevantToJob,
    };
}


