import { Box, Menu, MenuItem, Stack, StackProps } from "@mui/material";
import { Editor } from "@tiptap/react";
import React, { useState, forwardRef } from "react";
import { DropzoneInputProps } from "react-dropzone";

import { ReactComponent as BoldIcon } from "assets/icons/bold.svg";
import { ReactComponent as BulletsIcon } from "assets/icons/bullets.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";
import { ReactComponent as ItalicIcon } from "assets/icons/italic.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as NumberingIcon } from "assets/icons/numbering.svg";
import { ReactComponent as StrikethroughIcon } from "assets/icons/strikethrough.svg";
import { ReactComponent as UnderlineIcon } from "assets/icons/underline.svg";
import { ReactComponent as DoverIcon } from "assets/logos/DoverD.svg";
import { DOVER_INTERVIEWER } from "components/dover/top-level-modal-manager/constants";
import InsertLinkModal from "components/library/TipTap/InsertLinkModal";
import { MenuBarContext, SchedulingLinkConfig, TextTagOptions } from "components/library/TipTap/types";
import { useSaveAsNewTemplate } from "components/library/TipTap/useSaveAsNewTemplate";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { getFullNameInitials } from "utils/strings";

interface MenuBarProps extends StackProps {
  editor: Editor | null;
  context?: MenuBarContext;
  schedulingLinkConfig?: SchedulingLinkConfig;
  subject?: string;
  disabled?: boolean;
  hideSaveTemplateButton?: boolean;
  imageUploadProps?: DropzoneInputProps;
  openImageUpload?: (() => void) | null;
}

export const MenuBar = forwardRef<HTMLDivElement, MenuBarProps>(
  (
    {
      editor,
      context = MenuBarContext.None,
      schedulingLinkConfig,
      subject,
      disabled,
      hideSaveTemplateButton,
      imageUploadProps,
      openImageUpload,
      ...rest
    },
    ref
  ): React.ReactElement => {
    // Local state
    const [insertLinkModalOpen, setInsertLinkModalOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedTag, setSelectedTag] = useState<TextTagOptions>(TextTagOptions.Paragraph);

    // Event handlers
    const handleSaveAsNewTemplate = useSaveAsNewTemplate(editor, disabled, subject);

    const openMenu = (event: React.MouseEvent<HTMLElement>): void => {
      setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (): void => {
      setMenuAnchorEl(null);
    };

    // Effects
    const setSelectedTagHelper = (newSelectedTag: TextTagOptions): void => {
      if (!editor || disabled) {
        return;
      }

      setSelectedTag(newSelectedTag);

      switch (newSelectedTag) {
        case TextTagOptions.Paragraph:
          editor
            .chain()
            .setParagraph()
            .run();
          return;
        case TextTagOptions.H1:
          editor
            .chain()
            .setHeading({ level: 1 })
            .run();
          return;
        case TextTagOptions.H2:
          editor
            .chain()
            .setHeading({ level: 2 })
            .run();
          return;
        case TextTagOptions.H3:
          editor
            .chain()
            .setHeading({ level: 3 })
            .run();
          return;
        default:
          return;
      }
    };

    if (!editor) {
      return <></>;
    }

    const doverInterviewerSelected = schedulingLinkConfig && schedulingLinkConfig.label === DOVER_INTERVIEWER.fullName;
    const interviewerIcon = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          border: `solid 1px ${colors.grayscale.gray200}`,
          textTransform: "uppercase",
          svg: {
            width: "18px",
            height: "18px",
            position: "relative",
            top: "-1px",
          },
        }}
      >
        {doverInterviewerSelected ? (
          <DoverIcon />
        ) : (
          <BodyExtraSmall>{getFullNameInitials(schedulingLinkConfig?.label ?? "?")}</BodyExtraSmall>
        )}
      </Box>
    );

    return (
      <>
        {insertLinkModalOpen && (
          <InsertLinkModal
            isOpen={insertLinkModalOpen}
            closeModal={(): void => setInsertLinkModalOpen(false)}
            editor={editor}
          />
        )}
        <Stack
          ref={ref}
          width="100%"
          direction="row"
          alignItems="center"
          spacing={2}
          padding="8px 16px"
          {...rest}
          sx={{
            backgroundColor: disabled ? colors.grayscale.gray100 : colors.white,
            border: `1px solid ${colors.grayscale.gray300}`,
            borderBottom: "none",
            borderTopRightRadius: context === MenuBarContext.TopLevel ? "0px" : "4px",
            borderTopLeftRadius: context === MenuBarContext.TopLevel ? "0px" : "4px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            overflowX: "auto",
            ...(rest.sx ?? {}),
          }}
        >
          <>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              onClick={openMenu}
              sx={{
                padding: "2px 6px",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: colors.grayscale.gray100,
              }}
            >
              <BodySmall centered={true} weight="500">
                {selectedTag}
              </BodySmall>
              <ChevronDownIcon />
            </Stack>
            <Menu open={!!menuAnchorEl && !disabled} anchorEl={menuAnchorEl} onClose={closeMenu}>
              {Object.values(TextTagOptions).map(tag => (
                <MenuItem
                  key={tag}
                  onClick={(): void => {
                    setSelectedTagHelper(tag);
                  }}
                >
                  <BodySmall>{tag}</BodySmall>
                </MenuItem>
              ))}
            </Menu>
          </>
          <Stack direction="row" spacing={1}>
            <BoldIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleBold()
                  .run();
              }}
              className={editor.isActive("bold") ? "is-active svg-pointer" : "svg-pointer"}
            />
            <ItalicIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleItalic()
                  .run();
              }}
              className={editor.isActive("italic") ? "is-active svg-pointer" : "svg-pointer"}
            />
            <UnderlineIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleUnderline()
                  .run();
              }}
              className={editor.isActive("underline") ? "is-active svg-pointer" : "svg-pointer"}
            />
            <StrikethroughIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleStrike()
                  .run();
              }}
              className={editor.isActive("strike") ? "is-active svg-pointer" : "svg-pointer"}
            />
            <LinkIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                // if nothing is selected, do nothing
                if (editor.view.state.selection.content().size === 0) {
                  return;
                }

                // if selected is already a link
                if (editor.isActive("link")) {
                  editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .unsetLink()
                    .run();
                  return;
                }

                setInsertLinkModalOpen(true);
              }}
              className={editor.isActive("link") ? "is-active svg-pointer" : "svg-pointer"}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <BulletsIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleBulletList()
                  .run();
              }}
              className="svg-pointer"
            />
            <NumberingIcon
              onClick={(): void => {
                if (disabled) {
                  return;
                }

                editor
                  .chain()
                  .focus()
                  .toggleOrderedList()
                  .run();
              }}
              className="svg-pointer"
            />
          </Stack>
          {schedulingLinkConfig && (
            <Tooltip
              title={`Insert scheduling link for ${schedulingLinkConfig?.label || "interviewer"}`}
              placement="top"
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
                sx={{ cursor: "pointer" }}
                onClick={(): void => {
                  if (disabled) {
                    return;
                  }

                  editor.commands.insertContent(
                    `<a href="${schedulingLinkConfig.schedulingLink}">${schedulingLinkConfig.schedulingLink}</a>`
                  );
                }}
              >
                <CalendarIcon />
                <BodySmall weight="500">{`Scheduling Link`}</BodySmall>
                {schedulingLinkConfig?.label && interviewerIcon}
              </Stack>
            </Tooltip>
          )}
          {!hideSaveTemplateButton && (
            <Tooltip title="Generate email template from current email contents" placement="top">
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
                sx={{ cursor: "pointer" }}
                onClick={handleSaveAsNewTemplate}
              >
                <BodySmall weight="500">{`Save as new template`}</BodySmall>
              </Stack>
            </Tooltip>
          )}
          {openImageUpload && (
            <>
              <input {...imageUploadProps} />
              <ImageIcon onClick={openImageUpload} className={"svg-pointer"} />
            </>
          )}
        </Stack>
      </>
    );
  }
);
