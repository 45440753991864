import { useMemo } from "react";

import { useGetIncompleteCompanySetupStepsForFeature } from "services/doverapi/cross-endpoint-hooks/useGetIncompleteCompanySetupStepsForFeature";
import { useGetIncompleteJobSetupStepsForFeature } from "services/doverapi/cross-endpoint-hooks/useGetIncompleteJobSetupStepsForFeature";
import { useGetJobFeatureDisabledStatus } from "services/doverapi/cross-endpoint-hooks/useGetJobFeatureDisabledStatus";
import { JobFeatureDisabledStatus } from "services/doverapi/endpoints/job/types";
import { JobFeatureFeatureNameEnum } from "services/openapi";

export function useGetFeatureShouldBeEnabled(
  featureName: JobFeatureFeatureNameEnum,
  jobId: string | undefined
): boolean {
  const incompleteJobSetupStepsForFeature = useGetIncompleteJobSetupStepsForFeature(featureName, jobId);
  const incompleteClientSetupStepsForFeature = useGetIncompleteCompanySetupStepsForFeature(featureName, jobId);
  const jobFeatureDisabledStatus = useGetJobFeatureDisabledStatus(featureName, jobId);

  return useMemo(() => {
    const hasNoIncompleteJobSetupSteps =
      !incompleteJobSetupStepsForFeature || incompleteJobSetupStepsForFeature.length === 0;
    const hasNoIncompleteCompanySetupSteps =
      !incompleteClientSetupStepsForFeature || incompleteClientSetupStepsForFeature.length === 0;

    const featureIsNotDisabled =
      !jobFeatureDisabledStatus ||
      [JobFeatureDisabledStatus.NotDisabled, JobFeatureDisabledStatus.DisabledButDependentSetupStepsComplete].includes(
        jobFeatureDisabledStatus
      );

    return hasNoIncompleteJobSetupSteps && hasNoIncompleteCompanySetupSteps && featureIsNotDisabled;
  }, [incompleteJobSetupStepsForFeature, incompleteClientSetupStepsForFeature, jobFeatureDisabledStatus]);
}
