import { DefaultTheme } from "styled-components";

export const colors = {
  primary: {
    extraLight: "#F5FFFD",
    light: "#E7FCF5",
    base: "#3BA384",
    dark: "#23604D",
    hover: "#278367",
  },
  secondary: {
    light: "#A0CDDD",
    base: "#16333D",
  },
  brand: "#6ACCAF",
  link: "#2670BF",
  linkLight: "#327DCD",
  subTitle: "#909da3",
  opaqueBlue: "#14263B",
  darkBlue: "#071b30",

  success: {
    light: "#67C8AB",
    base: "#3BA384",
    dark: "#23604D",
  },

  warning: {
    light: "#FDF7EC",
    base: "#FBB83F",
    dark: "#DA9417",
  },
  // i.e. error red
  critical: {
    light: "#FFECEC",
    base: "#E06363",
    dark: "#AE2323",
    hover: "#CA4848",
  },

  informational: {
    light: "#EBF5FF",
    base: "#39849E",
    dark: "#105EB0",
  },

  black: "#171B1F",
  white: "#FFFFFF",
  muiChipGray: "#AEAEAE",
  grayscale: {
    gray700: "#101828",
    gray600: "#465361",
    gray500: "#667085",
    gray400: "#98A2B3",
    gray300: "#CFD7E0",
    gray200: "#E1E7EC",
    gray150: "#E9EEF2",
    gray100: "#F4F6F8",
    gray050: "#F1F1F1",
    gray025: "#FAFBFC",
  },

  decorative: {
    mintGreen: "#DAF5ED",
    highlightBlue: "#93ADF5",
    lightPurple: "#F0E6F4",
    purple: "#8f10c2",
  },

  channel: {
    inbound: "#FFA600",
    outbound: "#003F5C",
    referrals: "#BC5090",
  },

  lookAtMeYellow: "#FCDF3B",
};

export const baseColors = {
  primary: colors.primary.base,
  secondary: colors.secondary,
  brand: colors.brand,
  link: colors.link,
  black: colors.black,
  white: colors.white,
  success: colors.success.base,
  warning: colors.warning.base,
  critical: colors.critical.base,
  informational: colors.informational.base,
  gray: colors.grayscale.gray100,
};

export const backgrounds = {
  fog: "#F7FAFB",
  blue: "#FAFDFF",
  darkBlue: "#F5FAFF",
  darkBlueHome: "#071B30",
  green: "#F5FFF8",
  gray: "#F9FAFB",
  white: "#FFFFFF",
  yellow: "#FFF6E6",
  critical: "#FFECEC",
};

// see this reference: https://codepen.io/sosuke/pen/Pjoqqp
export const filters = {
  filterLink:
    "brightness(0) saturate(100%) invert(38%) sepia(34%) saturate(1674%) hue-rotate(178deg) brightness(94%) contrast(92%)",

  filterInformationalBase:
    "brightness(0) saturate(100%) invert(42%) sepia(58%) saturate(459%) hue-rotate(149deg) brightness(97%) contrast(85%)",

  filterGreen:
    "brightness(0) saturate(100%) invert(83%) sepia(14%) saturate(1070%) hue-rotate(109deg) brightness(89%) contrast(86%)",

  filterDarkGreen:
    " brightness(0) saturate(100%) invert(52%) sepia(26%) saturate(897%) hue-rotate(111deg) brightness(100%) contrast(84%)",

  filterRed:
    "brightness(0) saturate(100%) invert(58%) sepia(85%) saturate(3405%) hue-rotate(324deg) brightness(98%) contrast(78%)",

  filterDarkBlue:
    "brightness(0) saturate(100%) invert(13%) sepia(19%) saturate(1733%) hue-rotate(155deg) brightness(94%) contrast(89%)",

  filterGray30:
    "brightness(0) saturate(100%) invert(93%) sepia(10%) saturate(206%) hue-rotate(154deg) brightness(85%) contrast(89%)",

  filterGray400:
    "brightness(0) saturate(100%) invert(73%) sepia(11%) saturate(433%) hue-rotate(179deg) brightness(88%) contrast(83%)",

  filterGray700:
    "brightness(0) saturate(100%) invert(7%) sepia(24%) saturate(1371%) hue-rotate(181deg) brightness(96%) contrast(96%)",

  filterWhite:
    "brightness(0) saturate(100%) invert(97%) sepia(1%) saturate(0%) hue-rotate(209deg) brightness(119%) contrast(100%)",
};

const headers = {
  h0: {
    fontFamily: "Nunito Sans",
    fontSize: "56px",
    fontWeight: 600,
    lineHeight: "72px",
  },
  h1: {
    fontFamily: "Nunito Sans",
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "56px",
  },
  h2: {
    fontFamily: "Nunito Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "56px",
  },
  h3: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
  },
};

export const bodyText = {
  // named b2 in design system
  b0: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  // named b3 in design system
  b1: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  // named b4 in design system
  b2: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
};

const inputText = {
  // named Input/N2 in design system
  default: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  // named Input/N2-L in design system
  link: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textDecoration: "underline",
  },
  // named Input/N3 in design system
  bold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
};

const buttons = {
  primary: {
    color: colors.primary,
    background: colors.grayscale.gray050,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.grayscale.gray100}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterGreen,
    },

    "&:hover": {
      background: backgrounds.green,
      border: `1px solid ${colors.primary}`,
    },

    "&.active": {
      fontWeight: 600,
      background: backgrounds.green,
      border: `2px solid ${colors.primary}`,
    },

    "&:focus": {
      outline: 0,
    },
  },
  filled: {
    color: colors.grayscale.gray050,
    width: "auto",
    minWidth: "128px",
    border: "none",
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterWhite,
    },

    "&:hover": {
      background: colors.primary.dark,
    },

    "&.active": {
      fontWeight: 600,
      background: colors.primary.dark,
    },

    "&:focus": {
      outline: 0,
    },
  },
  secondary: {
    color: colors.secondary,
    background: colors.grayscale.gray050,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.grayscale.gray100}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterDarkBlue,
    },

    "&:hover": {
      background: backgrounds.blue,
      border: `1px solid ${colors.decorative.highlightBlue}`,
    },

    "&.active": {
      fontWeight: 600,
      background: "rgba(147, 173, 245, 0.15)",
      border: `1px solid ${colors.decorative.highlightBlue}`,
    },

    "&:focus": {
      outline: 0,
    },
  },
  disabled: {
    color: colors.grayscale.gray300,
    background: colors.grayscale.gray100,
    width: "auto",
    minWidth: "128px",
    border: "none",
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    svg: {
      filter: filters.filterGray30,
    },
  },
  error: {
    color: colors.critical.base,
    background: colors.grayscale.gray050,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.critical.base}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease",

    svg: {
      filter: filters.filterRed,
    },

    "&:hover, &.active": {
      color: colors.grayscale.gray050,
      background: colors.critical.base,

      svg: {
        filter: filters.filterWhite,
      },
    },

    "&:focus": {
      outline: 0,
    },
  },
  compact: {
    borderRadius: "4px",
    padding: "8px 12px",
  },
};

export const screenSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktopXS: "1600px",
  desktopS: "2000px",
  desktop: "2560px",
};

export const screenSizesNumbers = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  tabletL: 896,
  laptop: 1024,
  laptopM: 1150,
  laptopML: 1300,
  laptopL: 1440,
  desktopXS: 1600,
  desktopS: 2000,
  desktop: 2560,
};

export const device = {
  mobileS: `(min-width: ${screenSizes.mobileS})`,
  mobileM: `(min-width: ${screenSizes.mobileM})`,
  mobileL: `(min-width: ${screenSizes.mobileL})`,
  tablet: `(min-width: ${screenSizes.tablet})`,
  laptop: `(min-width: ${screenSizes.laptop})`,
  laptopL: `(min-width: ${screenSizes.laptopL})`,
  desktopS: `(min-width: ${screenSizes.desktopS})`,
  desktop: `(min-width: ${screenSizes.desktop})`,
  desktopL: `(min-width: ${screenSizes.desktop})`,
};

export const deviceHeight = {
  tablet: `(max-height: ${screenSizes.tablet})`,
};

export const theme: DefaultTheme = {
  colors: colors,
  baseColors: baseColors,
  headers: headers,
  body: bodyText,
  inputs: inputText,
  backgrounds: backgrounds,
  buttons: buttons,
  filters: filters,
  fontFamily: "Inter",
  gutterSize: "64px",
};

// Randomly generated - used as fallback colors for pro user "coins" when they are missing profile pictures
export const personaColors = [
  "#0078d4",
  "#0099bc",
  "#5c2d91",
  "#e3008c",
  "#b4009e",
  "#5c005c",
  "#008272",
  "#107c10",
  "#008055",
  "#007e8c",
  "#004b50",
  "#525e54",
  "#647c64",
  "#847545",
  "#7e735f",
  "#4b3b2b",
  "#5d341a",
  "#4e3b2a",
  "#6b3a19",
];
