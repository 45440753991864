import { AnyExtension } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";

/**
 * Shared props between all TipTap-based editors.
 * Divergences between editors are handled by extension this type or omitting fields explicitly.
 */
export interface CommonEditorProps {
  initialContent?: string;
  onContentChange?: (content: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  showPreviewTab?: boolean;
  customExtensions?: AnyExtension[];
  onModEnter?: () => void;
  skipSetContent?: boolean;
}

export enum EditorType {
  RawEditor = "RawEditor",
  MenuEditor = "MenuEditor",
}

export interface MentionsEditorProps extends CommonEditorProps {
  editorType?: EditorType;
}

/** Provides a scheduling link and button label to be used in the email editor menu */
export interface SchedulingLinkConfig {
  schedulingLink: string;
  label?: string;
}

export interface EmailEditorProps
  extends Omit<MentionsEditorProps, "initialContent" | "onContentChange" | "customExtensions"> {
  body: string;
  onBodyChanged?: (body: string) => void;
  subject?: string;
  onSubjectChanged?: (subject: string) => void;
  from?: EmailUser;
  fromOptions?: EmailUser[];
  onFromChanged?: (from: EmailUser) => void;
  to?: BasicEmailOption;
  toOptions?: BasicEmailOption[];
  onToChanged?: (to: BasicEmailOption) => void;
  cc?: BasicEmailOption[];
  bcc?: BasicEmailOption[];
  ccOptions?: BasicEmailOption[];
  onCcChanged?: (cc: BasicEmailOption[]) => void;
  onBccChanged?: (cc: BasicEmailOption[]) => void;
  variables?: string[];
  schedulingLinkConfig?: SchedulingLinkConfig;
  disabled?: boolean;
  disableFrom?: boolean;
  disableTo?: boolean;
  disableCc?: boolean;
  disableBcc?: boolean;
  disableSubject?: boolean;
  disableBody?: boolean;
  skipSetContent?: boolean;
  hideSaveTemplateButton?: boolean;
}

export enum TextTagOptions {
  Paragraph = "Paragraph",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
}

export interface MentionOption {
  label: string;
  userId: string;
  mentionerName: string;
  mentionerEmail: string;
  subheader?: string;
  isMe: boolean;
}

export interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void;
}

export interface MentionSuggestionProps extends SuggestionProps<MentionOption> {
  footer?: React.ReactNode;
}

export interface MentionsEditorInnerProps extends MentionsEditorProps {
  getOptions: () => MentionOption[];
  footer?: React.ReactNode;
}

/**
 * Defines the context of the menu bar.
 * For example, the menu bar may occur at the top level of an editor component.
 * Later, it may appear in other contexts.
 */
export enum MenuBarContext {
  None = "None",
  TopLevel = "TopLevel",
}

/**
 * Defines the owner of an email address.
 * Useful for grouping emails by owner, e.g. in a dropdown.
 */
export enum EmailOwner {
  AuthedUser = "AuthedUser",
  Dover = "Dover",
}

/**
 * Defines a basic email option for use in a dropdown, e.g. cc field
 */
export interface BasicEmailOption {
  id: string;
  email: string;
  label?: string;
}

/**
 * Defines an email user.
 * The caller is responsible for interpreting changing email users, e.g. mapping the id of the user to an object used for email sending.
 */
export interface EmailUser extends BasicEmailOption {
  pictureUrl?: string;
  isAuthed: boolean;
  owner: EmailOwner;
}

export interface EmailEditorHandle {
  setEditorContent: (content: string) => void;
  handleSaveAsNewTemplate?: () => Promise<void>;
}
