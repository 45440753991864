import { useDndMonitor, useDroppable } from "@dnd-kit/core";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC, ReactNode, useEffect } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { ButtonVariant } from "components/library/Button";
import { Overline } from "components/library/typography";
import { AddCandidateButton } from "sections/addcandidate";
import { HiringPipelineStage } from "services/openapi";
import { colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import { getCandidateFromMapAtom } from "views/candidates/CandidateTable/board/atoms";
import Page from "views/candidates/CandidateTable/board/components/Page";
import { BOARD_LIST_CANDIDATES_LIMIT } from "views/candidates/CandidateTable/board/hooks";
import { OpenAppReviewButton } from "views/candidates/CandidateTable/components/OpenAppReviewButton";
import { PipelineExpandOption } from "views/candidates/types";

interface ColumnProps {
  stage: HiringPipelineStage;
  count: number; // The total number of candidates in this column
}

const Column: FC<React.PropsWithChildren<ColumnProps>> = ({ stage, count }) => {
  // Total number of pages we could potentially render
  // render at least 1 page to show the empty state
  const numPages = Math.max(1, Math.ceil(count / BOARD_LIST_CANDIDATES_LIMIT));
  const lastIndex = numPages - 1; // The index of the last page
  const lastPageCount = count % BOARD_LIST_CANDIDATES_LIMIT; // How many items the last page will have

  const [collapsedState, setCollapsedState] = React.useState(stage?.name === "Contacted");
  const { active, isOver, setNodeRef } = useDroppable({
    id: stage.id,
    resizeObserverConfig: {
      disabled: false,
      updateMeasurementsFor: [],
    },
  });

  const getCandidate = useAtomValue(getCandidateFromMapAtom);
  const draggingCandidate = active && isOver ? getCandidate(active.id.toString()) : undefined;

  useDndMonitor({
    onDragMove(event) {
      if (event.collisions?.some(c => c.id.toString() === stage.id)) {
        setCollapsedState(false);
      }
    },
    onDragEnd(event) {
      if (event.active.id.toString() === stage.id) {
        setCollapsedState(false);
      } else {
        setCollapsedState(!count);
      }
    },
  });

  useEffect(() => {
    if (stage?.name !== "Contacted") {
      setCollapsedState(!count);
    }
  }, [count, stage?.name]);

  // we want to expand when we are using DnD
  const collapsed = collapsedState;

  const stageIsAppReview = isAppReviewStage(stage); // Calculate this here because it will be the same for the whole column

  const pageContent = (index: number): ReactNode => (
    <Stack spacing={1} mb={1}>
      <Page
        key={index}
        stageId={stage.id}
        page={index}
        count={index === lastIndex ? lastPageCount : BOARD_LIST_CANDIDATES_LIMIT}
        showMatchLabel={stageIsAppReview}
        expand={stage.name === "New Lead" ? PipelineExpandOption.CampaignMessageRequest : undefined}
        previewCandidate={draggingCandidate}
      />
    </Stack>
  );

  return (
    <Stack
      spacing={0.5}
      px={1}
      borderRight={`1px solid ${colors.grayscale.gray200}`}
      width={collapsed ? "50px" : undefined}
      ref={setNodeRef}
    >
      <Stack
        direction={collapsed ? "row-reverse" : "row"}
        alignItems="center"
        justifyContent="space-between"
        onClick={(): void => setCollapsedState(!collapsed)}
        sx={{ cursor: "pointer", transform: collapsed ? "rotate(-90deg)" : undefined }}
        mt={collapsed ? "10px" : undefined}
      >
        <Overline noWrap color={colors.grayscale.gray500}>
          {genColumnTitle(stage, count)}
        </Overline>
        {!collapsed && (
          <AddCandidateButton
            iconOnly
            buttonProps={{
              variant: ButtonVariant.Ghost,
              removePadding: true,
              removeOutline: true,
            }}
            hiringPipelineStageId={stage.id}
          />
        )}
      </Stack>
      {stageIsAppReview && !collapsed && (
        <Stack py={0.5} px={1}>
          <OpenAppReviewButton fullWidth />
        </Stack>
      )}
      <ColumnWrapper isOver={isOver} hidden={collapsed}>
        <Virtuoso style={{ height: "100%" }} totalCount={numPages} itemContent={pageContent} overscan={1500} />
      </ColumnWrapper>
    </Stack>
  );
};

const genColumnTitle = (stage: HiringPipelineStage, count: number): string => {
  return `${stage.name} (${count})`;
};

interface ColumnWrapperProps {
  isOver: boolean;
  hidden: boolean;
}

const ColumnWrapper = styled.div<ColumnWrapperProps>`
  display: ${({ hidden }): string => (hidden ? "none" : "block")};
  height: 100%;
  width: 275px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${({ isOver }): string | undefined => (isOver ? colors.grayscale.gray150 : undefined)};
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export default Column;
