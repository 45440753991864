import { NotificationConfig, NotificationConfigNotifTypeEnum } from "services/openapi";

export interface NotificationTableRow {
  id: number;
  label: string;
  type: string;
  description: string;
  defaultOn: boolean;
}

export interface EnrichedNotificationTableRow extends NotificationTableRow {
  emailSetting: boolean;
  original: NotificationConfig;
  job?: string | null;
  type: string;
}

export enum NotificationType {
  WeeklyNotifs = "WeeklyNotifs",
  InterviewsNotifs = "InterviewsNotifs",
  AppReviewNotifs = "AppReviewNotifs",
  SourcingAutopilot = "SourcingAutopilot",
}

export const weeklyNotifRows: NotificationTableRow[] = [
  {
    id: 1,
    label: "Weekly Updates",
    type: NotificationConfigNotifTypeEnum.WeeklyUpdates,
    description: "Weekly emails on your hiring funnel and upcoming interviews.",
    defaultOn: false,
  },
];

export const interviewsNotifRows: NotificationTableRow[] = [
  {
    id: 2,
    label: "Call Scheduled",
    type: NotificationConfigNotifTypeEnum.CallScheduled,
    description: "Notifications when interviews are scheduled.",
    defaultOn: true,
  },
  {
    id: 3,
    label: "Call Reminder",
    type: NotificationConfigNotifTypeEnum.CallReminder,
    description: "Notifications 24 hours and 1 hour before interviews start.",
    defaultOn: true,
  },
  {
    id: 4,
    label: "Call Completed",
    type: NotificationConfigNotifTypeEnum.CallCompleted,
    description: "Notifications after interviews are completed.",
    defaultOn: true,
  },
];

export const appReviewRows: NotificationTableRow[] = [
  {
    id: 5,
    label: "Daily Applicant Summary",
    type: NotificationConfigNotifTypeEnum.DailyApplicantSummary,
    description: "Daily summaries of new applicants.",
    defaultOn: true,
  },
  {
    id: 6,
    label: "Top Applicants",
    type: NotificationConfigNotifTypeEnum.NewBestMatchApplicant,
    description: "Notifications for new “best match” applicants.",
    defaultOn: false,
  },
  {
    id: 9,
    label: "Candidate Unsnoozed",
    type: NotificationConfigNotifTypeEnum.CandidateUnsnoozed,
    description: "Notification when a candidate is unsnoozed in jobs where you are the hiring manager or recruiter.",
    defaultOn: true,
  },
];

export const sourcingAutopilotRows: NotificationTableRow[] = [
  {
    id: 7,
    label: "Interested Candidate",
    type: NotificationConfigNotifTypeEnum.NewInterested,
    description: "Notifications when interested candidates respond to outreach.",
    defaultOn: false,
  },
  {
    id: 8,
    label: "Resume",
    type: NotificationConfigNotifTypeEnum.JobUnsnoozeReminder,
    description: "Notification 24 hours before Sourcing unpauses.",
    defaultOn: true,
  },
];
