import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Color from "color";
import React from "react";

import ReportingSplashImage from "assets/icons/reporting-splash-page.png";
import { GradientBackground } from "components/library/GradientBackground";
import { TitleLarge, Subtitle1 } from "components/library/typography";
import { colors, screenSizesNumbers } from "styles/theme";

const EnableThirdPartyCookiesInstructions = (): React.ReactElement => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));

  // below is from https://stackoverflow.com/questions/4565112/how-to-find-out-if-the-user-browser-is-chrome
  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isFirefox = winNav.userAgent.indexOf("Firefox") > -1;
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1;
  const isGoogleChrome =
    typeof (winNav as any).userAgentData !== "undefined"
      ? (winNav as any).userAgentData?.brands[0]?.brand === "Google Chrome"
      : vendorName === "Google Inc.";

  let browserSpecificMessage: React.ReactNode = null;

  if (isIOSChrome || isChromium || isGoogleChrome) {
    browserSpecificMessage = (
      <>
        Instructions for Google Chrome can be found{" "}
        <a
          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </>
    );
  }
  if (isSafari) {
    browserSpecificMessage = (
      <>
        If you are using Safari, you can follow these instructions:
        <ul style={{ listStyleType: "none", paddingLeft: "20px", paddingTop: "10px" }}>
          <li>1. Go to the Safari app on your Mac.</li>
          <li>2. Choose Safari {">"} Settings, then click Privacy.</li>
          <li>3. Uncheck “Prevent cross-site tracking.”</li>
        </ul>
      </>
    );
  }
  if (isFirefox) {
    browserSpecificMessage = (
      <>
        Instructions for Firefox can be found{" "}
        <a
          href="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </>
    );
  }
  if (isIEedge) {
    browserSpecificMessage = (
      <>
        Instructions for Microsoft Edge can be found{" "}
        <a
          href="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </>
    );
  }
  return (
    <>
      <GradientBackground
        spacing={4}
        direction={isSmallScreen ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        minHeight={isSmallScreen ? "auto" : "700px"}
        m={2}
        p={6}
        style={{
          border: `1px solid ${colors.grayscale.gray200}`,
          borderRadius: "16px",
        }}
        angle="90"
        firstColor={colors.warning.base}
        firstColorPercent="0.1"
        secondColor="rgba(250, 253, 255, 0)"
        secondColorPercent="57.08"
      >
        <Stack spacing={4} maxWidth={isSmallScreen ? "100%" : "60%"}>
          <Stack spacing={2}>
            <TitleLarge
              style={{
                fontSize: isSmallScreen ? "32px" : "40px",
                fontWeight: 800,
                lineHeight: isSmallScreen ? "40px" : "48px",
                color: Color(colors.primary.dark)
                  .darken(0.3)
                  .toString(),
              }}
            >
              Reporting Requires Third Party Cookies
            </TitleLarge>
            <Subtitle1>
              You need to enable third-party cookies to view this content. Please enable third-party cookies in your
              browser settings.
            </Subtitle1>
            <Subtitle1>{browserSpecificMessage}</Subtitle1>
          </Stack>
        </Stack>

        {!isSmallScreen && (
          <Box
            width="45%"
            position="relative"
            alignSelf="stretch"
            display="flex"
            alignItems="flex-end"
            sx={{ height: "600px" }}
          >
            <img
              src={ReportingSplashImage}
              alt="Reporting analytics preview"
              style={{
                width: "100%",
                position: "absolute",
                height: "auto",
                maxHeight: "600px",
                bottom: "-48px",
                right: 0,
                objectFit: "contain",
                objectPosition: "bottom",
                borderRadius: "8px 8px 0 0",
              }}
            />
          </Box>
        )}
      </GradientBackground>
    </>
  );
};
export default EnableThirdPartyCookiesInstructions;
