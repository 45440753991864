import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Chip,
  Divider,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { EditorContent } from "@tiptap/react";
import { memoize, uniqBy } from "lodash";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { AuthButton } from "components/library/TipTap/AuthButton";
import { EditOrPreviewTabBar } from "components/library/TipTap/EditOrPreviewTabBar";
import { VariableHighlighter } from "components/library/TipTap/extensions/variableHighlighter/variableHighlighter";
import {
  useInitialEditorSetup,
  useSingleLineInitialEditorSetup,
  useOnVariablesUpdate,
} from "components/library/TipTap/hooks";
import { MenuBar } from "components/library/TipTap/MenuBar";
import {
  BasicEmailOption,
  EmailEditorHandle,
  EmailEditorProps,
  EmailOwner,
  EmailUser,
  MenuBarContext,
} from "components/library/TipTap/types";
import { useSaveAsNewTemplate } from "components/library/TipTap/useSaveAsNewTemplate";
import {
  constructFromFieldString,
  getFirstNameAndLastNameFromBasicEmailOptions,
} from "components/library/TipTap/utils";
import { BodySmall, Caption } from "components/library/typography";
import { PersonaCoin, PersonaCoinWithWarningBadge } from "components/PersonaCoin";
import { colors } from "styles/theme";
import "components/library/TipTap/TipTapEditorStyles.scss";

const DATA_FIELD_SHARED_PROPS: SxProps<Theme> = {
  backgroundColor: colors.white,
  overflowX: "auto",
};

interface GetRefinedEmailUserOptionsArgs {
  selected?: BasicEmailOption | BasicEmailOption[];
  options?: BasicEmailOption[];
}

/** Ensures that the selected user is in the list of options */
const getRefinedEmailUserOptions = memoize(({ selected, options }: GetRefinedEmailUserOptionsArgs):
  | BasicEmailOption[]
  | undefined => {
  // If not options, then this is undefined behavior
  if (options === undefined) {
    return undefined;
  }

  // It's possible for nothing to be selected, in which case we simply return the passed in options
  if (selected === undefined) {
    return options;
  }

  // If there is an array of selected users, then we need to make sure that all of them are in the list of options.
  if (Array.isArray(selected)) {
    return selected.reduce((acc, user) => {
      if (options.find(option => option.id === user.id)) {
        return acc;
      }

      return [...acc, user];
    }, options);
  }

  // If there is only one selected user, then we need to make sure that it is in the list of options.
  if (options.find(option => option.id === selected.id)) {
    return options;
  }

  // If the selected user is not in the list of options, then we need to add it to the list of options.
  return [selected, ...options];
});

type CcButtonProps = Pick<EmailEditorProps, "onCcChanged"> & {
  showCcField: boolean;
  setShowCcField: (showCcField: boolean) => void;
  label: string;
};

/** Basic button to show the cc or bcc field */
const CcButton: React.FC<React.PropsWithChildren<CcButtonProps>> = ({
  onCcChanged,
  showCcField,
  setShowCcField,
  label,
}) => {
  // If there is no onCcChanged callback, then we don't need to render the cc button
  if (onCcChanged === undefined) {
    return null;
  }

  return (
    <Box
      color={showCcField ? colors.linkLight : colors.grayscale.gray500}
      onClick={(): void => {
        // If the cc field is not already showing, then we need to show it as initially empty
        if (!showCcField) {
          onCcChanged([]);
        }
        setShowCcField(!showCcField);
      }}
      sx={{
        fontSize: "14px",
        font: "Inter",
        fontWeight: "400",
        cursor: "pointer",
        ":hover": {
          color: colors.link,
        },
      }}
    >
      {label}
    </Box>
  );
};

interface EmailEditorReadOnlyFieldProps {
  label: string;
  text: string;
  endAdornment?: React.ReactNode;
  sxOverride?: SxProps<Theme>;
  disabled?: boolean;
}

/** Basic read-only field for the email editor */
const EmailEditorReadOnlyField: React.FC<React.PropsWithChildren<EmailEditorReadOnlyFieldProps>> = ({
  label,
  text,
  endAdornment,
  sxOverride,
  disabled,
}) => {
  return (
    <Stack
      width="100%"
      direction="row"
      spacing={1}
      padding="10px 16px"
      alignItems="center"
      sx={{
        ...DATA_FIELD_SHARED_PROPS,
        backgroundColor: disabled ? colors.grayscale.gray100 : colors.white,
        ...sxOverride,
      }}
    >
      <BodySmall color={colors.grayscale.gray500}>{label}</BodySmall>
      {/* Take up all remaining space with the text contents */}
      <Box flexGrow={1}>
        <BodySmall color={colors.grayscale.gray700}>{text}</BodySmall>
      </Box>
      {!!endAdornment && endAdornment}
    </Stack>
  );
};

interface SenderReadOnlyFieldProps {
  sender: EmailUser;
  disabled?: boolean;
}

/** Sender read-only field */
const SenderReadOnlyField: React.FC<React.PropsWithChildren<SenderReadOnlyFieldProps>> = ({ sender, disabled }) => {
  const text = constructFromFieldString(sender.label, sender.email);
  const senderNeedsAuth = doesSenderNeedAuth(sender);
  const firstNameAndLastName = getFirstNameAndLastNameFromBasicEmailOptions(sender);

  return (
    <Stack
      flexGrow={1}
      direction="row"
      spacing={2}
      padding="8px 16px"
      alignItems="center"
      sx={{
        ...DATA_FIELD_SHARED_PROPS,
        backgroundColor: disabled ? colors.grayscale.gray100 : colors.white,
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <BodySmall color={colors.grayscale.gray500}>{"From: "}</BodySmall>
      <Stack width="100%" spacing={0.5} sx={{ marginLeft: "0px !important" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          border={`1px solid ${senderNeedsAuth ? colors.warning.base : undefined}`}
          borderRadius={"4px"}
          padding="8px"
          sx={{ marginLeft: "0px !important" }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            {sender.pictureUrl && (
              <PersonaCoinWithWarningBadge
                id={sender.id}
                firstName={firstNameAndLastName?.firstName}
                lastName={firstNameAndLastName?.lastName}
                size={"small"}
                pictureUrl={sender.pictureUrl}
                showWarningBadge={senderNeedsAuth}
              />
            )}
            <BodySmall color={colors.grayscale.gray700}>{text}</BodySmall>
          </Stack>
          {senderNeedsAuth && <AuthButton />}
        </Stack>
        {senderNeedsAuth && (
          <Caption color={colors.warning.dark}>Email address must be authorized to send emails</Caption>
        )}
      </Stack>
    </Stack>
  );
};

interface SenderFieldProps {
  sender: EmailUser;
  senderOptions?: EmailUser[];
  onSenderChanged?: (option: EmailUser) => void;
  disabled?: boolean;
}

/** Sender field for the email editor */
const SenderField: React.FC<React.PropsWithChildren<SenderFieldProps>> = ({
  sender,
  senderOptions,
  onSenderChanged,
  disabled,
}) => {
  const senderOptionsRefined = getRefinedEmailUserOptions({
    selected: sender,
    options: senderOptions,
  }) as EmailUser[]; // Safe cast since the inputs are both EmailUser types

  if (senderOptionsRefined === undefined || senderOptionsRefined.length === 1 || onSenderChanged === undefined) {
    // If there are not options, we simply display the non-editable "from" field
    return <SenderReadOnlyField sender={sender} disabled={disabled} />;
  }

  const senderNeedsAuth = doesSenderNeedAuth(sender);

  // Make sure not show the same option multiple times to prevent email duplication
  const dedupedOptions = uniqBy(senderOptionsRefined, "email");
  const nonDoverOptions = dedupedOptions.filter(option => option.owner === EmailOwner.AuthedUser);
  const doverOptions = dedupedOptions.filter(option => option.owner === EmailOwner.Dover);

  return (
    <Stack
      flexGrow={1}
      direction="row"
      spacing={2}
      padding="8px 16px"
      alignItems="center"
      sx={{
        ...DATA_FIELD_SHARED_PROPS,
        backgroundColor: disabled ? colors.grayscale.gray100 : colors.white,
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <BodySmall color={colors.grayscale.gray500}>{"From: "}</BodySmall>
      <Stack width="100%" spacing={0.5}>
        <TextField
          disabled={disabled}
          value={sender.id}
          fullWidth={true}
          select={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const newOption = senderOptionsRefined.find(option => option.id === event.target.value);
            if (newOption !== undefined) {
              onSenderChanged(newOption);
            }
          }}
          sx={{
            ".MuiInputBase-root": {
              backgroundColor: `${disabled ? colors.grayscale.gray100 : colors.white} !important`,
              // If the sender is not authed and is not a Dover account, then we need to show a warning border
              borderColor: senderNeedsAuth ? colors.warning.base : undefined,
              input: {
                padding: "7px 14px 7px 8px",
                fontSize: "14px",
                fontFamily: "Inter",
                color: colors.grayscale.gray700,
              },
              ".MuiInputBase-input": {
                paddingBottom: "7px",
              },
              fieldset: {
                display: "none",
              },
            },
            ".MuiSelect-select": {
              paddingY: "7px",
            },
          }}
        >
          {nonDoverOptions.map(option => {
            const firstNameAndLastName = getFirstNameAndLastNameFromBasicEmailOptions(option);
            return (
              <MenuItem key={option.id} value={option.id}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                  <Stack alignItems="center" direction="row" spacing={1}>
                    {option.pictureUrl && (
                      <PersonaCoinWithWarningBadge
                        id={option.id}
                        firstName={firstNameAndLastName?.firstName}
                        lastName={firstNameAndLastName?.lastName}
                        size={"small"}
                        pictureUrl={option.pictureUrl}
                        showWarningBadge={!option.isAuthed}
                      />
                    )}
                    <BodySmall color={colors.grayscale.gray700}>
                      {constructFromFieldString(option.label, option.email)}
                    </BodySmall>
                  </Stack>
                  {!option.isAuthed && <AuthButton />}
                </Stack>
              </MenuItem>
            );
          })}
          <ListItemText
            primary="Dover accounts"
            sx={{
              ".MuiTypography-root": {
                fontSize: "14px",
                color: colors.grayscale.gray500,
                fontWeight: "600",
                paddingLeft: "10px",
                position: "relative",
                top: "5px",
              },
            }}
          />
          {doverOptions.map(option => {
            return (
              <MenuItem key={option.id} value={option.id}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <PersonaCoin id={option.id} useDoverLogo={true} size={"small"} />
                  <BodySmall color={colors.grayscale.gray700}>
                    {constructFromFieldString(option.label, option.email)}
                  </BodySmall>
                </Stack>
              </MenuItem>
            );
          })}
        </TextField>
        {senderNeedsAuth && (
          <Caption color={colors.warning.dark}>Email address must be authorized to send emails</Caption>
        )}
      </Stack>
    </Stack>
  );
};

interface RecipientFieldProps {
  recipient: BasicEmailOption;
  recipientOptions?: BasicEmailOption[];
  onRecipientChanged?: (option: BasicEmailOption) => void;
  ccAndBccButtons?: React.ReactNode;
  disabled?: boolean;
}

/** Recipient field for the email editor */
const RecipientField: React.FC<React.PropsWithChildren<RecipientFieldProps>> = ({
  recipient,
  recipientOptions,
  onRecipientChanged,
  ccAndBccButtons,
  disabled,
}) => {
  const recipientOptionsRefined = getRefinedEmailUserOptions({ selected: recipient, options: recipientOptions });
  if (
    recipientOptionsRefined === undefined ||
    recipientOptionsRefined.length === 1 ||
    onRecipientChanged === undefined
  ) {
    // If there are not options, we simply display the non-editable "to" field
    return (
      <EmailEditorReadOnlyField
        label={"To: "}
        text={constructFromFieldString(recipient.label, recipient.email)}
        endAdornment={ccAndBccButtons}
        disabled={disabled}
      />
    );
  }

  // Make sure not show the same option multiple times to prevent email duplication
  const dedupedOptions = uniqBy(recipientOptionsRefined, "email");

  return (
    <Stack
      flexGrow={1}
      direction="row"
      spacing={2}
      padding="8px 16px"
      alignItems="center"
      sx={{
        ...DATA_FIELD_SHARED_PROPS,
        backgroundColor: disabled ? colors.grayscale.gray100 : colors.white,
      }}
    >
      <BodySmall color={colors.grayscale.gray500}>{"To: "}</BodySmall>
      <TextField
        disabled={disabled}
        value={recipient.id}
        fullWidth={true}
        select={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const newOption = recipientOptionsRefined.find(option => option.id === event.target.value);
          if (newOption !== undefined) {
            onRecipientChanged(newOption);
          }
        }}
        sx={{
          ".MuiInputBase-root": {
            backgroundColor: `${disabled ? colors.grayscale.gray100 : colors.white} !important`,
            input: {
              padding: "7px 14px 7px 8px",
              fontSize: "14px",
              fontFamily: "Inter",
              color: colors.grayscale.gray700,
            },
            ".MuiInputBase-input": {
              paddingBottom: "7px",
            },
            fieldset: {
              display: "none",
            },
          },
          ".MuiSelect-select": {
            paddingY: "7px",
          },
        }}
      >
        {dedupedOptions.map(option => {
          return (
            <MenuItem key={option.id} value={option.id}>
              <BodySmall color={colors.grayscale.gray700}>
                {constructFromFieldString(option.label, option.email)}
              </BodySmall>
            </MenuItem>
          );
        })}
      </TextField>
      {!!ccAndBccButtons && ccAndBccButtons}
    </Stack>
  );
};

interface EmailEditorAutocompleteProps {
  selected?: BasicEmailOption[];
  options?: BasicEmailOption[];
  onChanged?: (options: BasicEmailOption[]) => void;
  label: string;
  disabled?: boolean;
}

/**
 * Who is being cc'd on the email?
 * This may include options to add
 * */
const EmailEditorAutocomplete: React.FC<React.PropsWithChildren<EmailEditorAutocompleteProps>> = ({
  selected,
  options,
  onChanged,
  label,
  disabled,
}) => {
  const optionsRefined = getRefinedEmailUserOptions({ selected, options });
  // Make sure not show the same option multiple times to prevent email duplication
  const dedupedOptions = optionsRefined ? uniqBy(options, "email") : undefined;

  if (onChanged === undefined) {
    // If there is no change, we simply display the non-editable "cc" field

    // However, if there are no selected options, we don't want to render anything
    if (selected === undefined || selected.length === 0) {
      return null;
    }

    const ccText = selected.map(option => constructFromFieldString(option.label, option.email)).join(", ");
    return (
      <EmailEditorReadOnlyField label={label} text={ccText} sxOverride={{ borderRadius: "0px" }} disabled={disabled} />
    );
  }

  // Otherwise, we use a select field to allow the user to change the "cc" field
  return (
    <Stack
      width="100%"
      direction="row"
      spacing={1}
      padding="10px 16px"
      alignItems="center"
      sx={{ ...DATA_FIELD_SHARED_PROPS, backgroundColor: disabled ? colors.grayscale.gray100 : colors.white }}
    >
      <BodySmall color={colors.grayscale.gray500}>{label}</BodySmall>
      <Autocomplete
        disabled={disabled}
        multiple
        freeSolo
        autoSelect
        filterSelectedOptions
        options={dedupedOptions ?? []}
        value={selected ?? []}
        isOptionEqualToValue={(option: BasicEmailOption, value: BasicEmailOption): boolean => {
          return option.email === value.email;
        }}
        onChange={(_event: React.ChangeEvent<{}>, value: (string | BasicEmailOption)[]): void => {
          // If the user has selected a new option, we need to add it to the list of cc'd users
          // First, convert all string values to BasicEmailOptions
          const newCcOptions: BasicEmailOption[] = value.map(option => {
            if (typeof option === "string") {
              return { email: option, id: uuidv4() } as BasicEmailOption;
            }
            return option;
          });

          onChanged(newCcOptions);
        }}
        getOptionLabel={(option: string | BasicEmailOption): string =>
          // If the option is a string from free solo input, we simply return the string
          // Otherwise, we construct the label from the option
          typeof option === "string" ? option : constructFromFieldString(option.label, option.email)
        }
        renderInput={(params): React.ReactElement => (
          <TextField {...params} placeholder={selected && selected.length > 0 ? undefined : "Type an email"} />
        )}
        fullWidth
        renderTags={(value: BasicEmailOption[], getTagProps: AutocompleteRenderGetTagProps): React.ReactNode => {
          return value.map((option, index) => (
            <Chip
              variant="outlined"
              label={constructFromFieldString(option.label, option.email)}
              size="small"
              deleteIcon={<HighlightOffSharpIcon />}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: colors.grayscale.gray100,
                borderColor: "transparent",
                ".MuiSvgIcon-root": {
                  color: colors.grayscale.gray500,
                },
              }}
            />
          ));
        }}
        sx={{
          ".MuiInputBase-root": {
            paddingY: "0px",
            backgroundColor: `${disabled ? colors.grayscale.gray100 : colors.white} !important`,
            fieldset: {
              display: "none",
            },
          },
        }}
      />
    </Stack>
  );
};

/**
 * @property body - the body of the email
 * @property onBodyChanged - callback for when the body changes
 * @property subject - the subject of the email
 * @property onSubjectChanged - callback for when the subject changes. If not provided, subject is read-only.
 * @property from - the user sending the email
 * @property fromOptions - the list of users that can be selected as the sender. If not provided, the sender is read-only.
 * @property onFromChanged - callback for when the sender changes. If not provided, the sender is read-only.
 * @property to - the user receiving the email
 * @property toOptions - the list of users that can be selected as the recipient. If not provided, the recipient is read-only.
 * @property onToChanged - callback for when the recipient changes. If not provided, the recipient is read-only.
 * @property cc - the list of users that are cc'd on the email
 * @property onCcChanged - callback for when the cc list changes. If not provided, the cc list is read-only.
 * @property bcc - the list of users that are bcc'd on the email
 * @property onBccChanged - callback for when the bcc list changes. If not provided, the bcc list is read-only.
 * @property ccOptions - the list of users that can be selected as cc recipients. If not provided, the cc field is not shown.
 * @property placeholder - the placeholder text for the email body
 * @property readOnly - whether the email editor is read-only
 * @property onModEnter - callback for when the user presses mod+enter
 * @property variables - the case-insensitive list of variables that will be highlighted in the email body
 * @property schedulingLinkConfig - the configuration for scheduling links
 * @property disabled - whether the whole email editor is disabled, including all fields
 * @property disableFrom - whether the from field is disabled
 * @property disableTo - whether the to field is disabled
 * @property disableCc - whether the cc field is disabled
 * @property disableBcc - whether the bcc field is disabled
 * @property disableSubject - whether the subject field is disabled
 * @property disableBody - whether the body field is disabled
 * @property skipSetContent - whether or not to skip updating email content state with Tiptap
 */
const EmailEditorRenderFunction: React.ForwardRefRenderFunction<EmailEditorHandle, EmailEditorProps> = (
  {
    body,
    onBodyChanged,
    subject,
    onSubjectChanged,
    from,
    fromOptions,
    onFromChanged,
    to,
    toOptions,
    onToChanged,
    cc,
    bcc,
    onCcChanged,
    onBccChanged,
    ccOptions,
    placeholder,
    readOnly,
    showPreviewTab,
    onModEnter,
    variables,
    schedulingLinkConfig,
    disabled,
    disableFrom,
    disableTo,
    disableCc,
    disableBcc,
    disableSubject,
    disableBody,
    skipSetContent,
    hideSaveTemplateButton,
  },
  forwardedRef
) => {
  const emailBodyEditor = useInitialEditorSetup({
    initialContent: body,
    onContentChange: onBodyChanged,
    placeholder,
    readOnly: readOnly || disabled || disableBody,
    onModEnter,
    customExtensions: [VariableHighlighter],
    skipSetContent,
  });

  const subjectLineEditor = useSingleLineInitialEditorSetup({
    initialContent: subject,
    // If not change handler passed, do nothing
    onContentChange: onSubjectChanged ?? ((): void => {}),
    readOnly: readOnly || !onSubjectChanged || disabled || disableSubject,
    customExtensions: [VariableHighlighter],
    skipSetContent,
  });

  // Listen to variables update so we make sure to always highlight the latest variables
  useOnVariablesUpdate(emailBodyEditor, variables);
  useOnVariablesUpdate(subjectLineEditor, variables);

  const handleSaveAsNewTemplate = useSaveAsNewTemplate(emailBodyEditor, disabled || disableBody, subject);

  // Expose imperative handle to consumer so they can programmtically set content of editor
  useImperativeHandle(forwardedRef, () => {
    return {
      setEditorContent(content: string): void {
        emailBodyEditor?.commands.setContent(content);
      },
      handleSaveAsNewTemplate,
    };
  });

  const [showCcField, setShowCcField] = React.useState<boolean>(false);
  const [showBccField, setShowBccField] = React.useState<boolean>(false);

  // Default show cc or bcc fields if they are provided
  useEffect(() => {
    if (cc) {
      setShowCcField(true);
    }

    if (bcc) {
      setShowBccField(true);
    }
  }, [bcc, cc]);

  // Who is the email coming from?
  // This may include multiple options
  const fromField = useMemo(() => {
    if (!from) {
      return <></>;
    }
    return (
      <SenderField
        sender={from}
        senderOptions={fromOptions}
        onSenderChanged={onFromChanged}
        disabled={disabled || disableFrom}
      />
    );
  }, [disabled, disableFrom, from, fromOptions, onFromChanged]);

  const ccAndBccButtons = useMemo(() => {
    // If neither button is needed, don't bother rendering the container Stack
    if (onCcChanged === undefined && onBccChanged === undefined) {
      return null;
    }

    return (
      <Stack direction="row" spacing={1}>
        <CcButton onCcChanged={onCcChanged} showCcField={showCcField} setShowCcField={setShowCcField} label={"Cc"} />
        <CcButton
          onCcChanged={onBccChanged}
          showCcField={showBccField}
          setShowCcField={setShowBccField}
          label={"Bcc"}
        />
      </Stack>
    );
  }, [onBccChanged, onCcChanged, showBccField, showCcField]);

  // Who is the email going to?
  // This may include multiple options
  const toField = useMemo(() => {
    if (!to) {
      return null;
    }

    return (
      <RecipientField
        recipient={to}
        recipientOptions={toOptions}
        onRecipientChanged={onToChanged}
        ccAndBccButtons={ccAndBccButtons}
        disabled={disabled || disableTo}
      />
    );
  }, [ccAndBccButtons, disableTo, disabled, onToChanged, to, toOptions]);

  // What is the subject of the email?
  const subjectField = useMemo(() => {
    if (subject === undefined) {
      return null;
    }

    // If there is no change handler, then this is a read-only field
    if (readOnly || !onSubjectChanged) {
      return <EmailEditorReadOnlyField label={"Subject: "} text={subject} disabled={disabled || disableSubject} />;
    }

    return (
      <Stack
        flexGrow={1}
        direction="row"
        spacing={1}
        padding="10px 16px"
        alignItems="center"
        sx={{
          ...DATA_FIELD_SHARED_PROPS,
          backgroundColor: disabled || disableSubject ? colors.grayscale.gray100 : colors.white,
        }}
      >
        <BodySmall color={colors.grayscale.gray500}>{"Subject: "}</BodySmall>
        <EditorContent
          spellCheck={false}
          editor={subjectLineEditor}
          className={disabled || disableSubject ? "SubjectContainer DisabledEmailEditor" : "SubjectContainer"}
        />
      </Stack>
    );
  }, [disableSubject, disabled, onSubjectChanged, readOnly, subject, subjectLineEditor]);

  const ccField = useMemo(() => {
    if (!showCcField) {
      return null;
    }

    return (
      <EmailEditorAutocomplete
        selected={cc}
        options={ccOptions}
        onChanged={onCcChanged}
        label={"Cc: "}
        disabled={disabled || disableCc}
      />
    );
  }, [cc, ccOptions, disableCc, disabled, onCcChanged, showCcField]);

  const bccField = useMemo(() => {
    if (!showBccField) {
      return null;
    }

    return (
      <EmailEditorAutocomplete
        selected={bcc}
        options={ccOptions}
        onChanged={onBccChanged}
        label={"Bcc: "}
        disabled={disabled || disableBcc}
      />
    );
  }, [bcc, ccOptions, disableBcc, disabled, onBccChanged, showBccField]);

  // Final markup for the email editor
  return (
    <Stack width="100%" flexGrow={1} minHeight={0}>
      {showPreviewTab && <EditOrPreviewTabBar />}
      <Stack
        width="100%"
        minHeight={0}
        divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray300 }} />}
        sx={{
          borderTop: showPreviewTab ? "0px !important" : undefined,
          borderRadius: "4px 4px 0px 0px",
          border: `1px solid ${colors.grayscale.gray300}`,
          borderBottom: "none",
        }}
      >
        {fromField}
        {toField}
        {ccField && ccField}
        {bccField && bccField}
        {subjectField}
      </Stack>
      <MenuBar
        editor={emailBodyEditor}
        disabled={disabled || disableBody}
        context={MenuBarContext.TopLevel}
        schedulingLinkConfig={schedulingLinkConfig}
        subject={subject}
        hideSaveTemplateButton={hideSaveTemplateButton}
      />
      <EditorContent
        spellCheck={true}
        editor={emailBodyEditor}
        className={
          disabled || disableBody
            ? "EditorContainer EmailContainer DisabledEmailEditor"
            : "EditorContainer EmailContainer"
        }
      />
    </Stack>
  );
};

export const doesSenderNeedAuth = (sender: EmailUser): boolean =>
  sender.owner === EmailOwner.AuthedUser && !sender.isAuthed;

export const EmailEditor = React.forwardRef(EmailEditorRenderFunction);
export type EmailEditorRefType = React.ElementRef<typeof EmailEditor>;
