import { Divider, SelectChangeEvent, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import { ENTER } from "App/hotkeys/hotkeys";
import { useHotkey } from "App/hotkeys/useHotkey";
import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useApplicationId } from "components/dover/top-level-modal-manager/hooks/useApplicationId";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { useInterviewer } from "components/dover/top-level-modal-manager/hooks/useInterviewer";
import { useSchedulingOwnership } from "components/dover/top-level-modal-manager/hooks/useSchedulingOwnership";
import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import {
  CandidateActionEmailEditor,
  CandidateActionEmailEditorRefType,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor";
import {
  SchedulingLinkBanner,
  WarningBanner,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { ClientEmailTemplateSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/ClientEmailTemplateSelect";
import { DurationSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/DurationSelect";
import {
  InterviewerSelect,
  shouldDisableForInterviewerPreferences,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerSelect";
import { StageSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/StageSelect";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { createInterviewPanel } from "components/dover/top-level-modal-manager/utils/createInterviewPanel";
import { getModalWidth, isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import { isInterviewerEmpty } from "components/dover/top-level-modal-manager/utils/isInterviewerEmpty";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { DOVER_INTERVIEWER_ID } from "components/NextInterviewer/usePossibleInterviewers";
import { ScheduledActionButton } from "components/ScheduledActionButton";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import { useQueueEmailMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import {
  BaseInterviewSubstage,
  CandidateActionEmailArgs,
  GetEmailTemplateRequestV2DecisionEnum,
  SubmitDecisionRequestDecisionEnum,
} from "services/openapi";
import { toastOptions } from "utils/showToast";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

const SchedulingModal = ({ isOpen, closeModal, candidateId }: CandidateActionModalProps): React.ReactElement => {
  const hasImprovedSchedulingUX = useFeatureFlag(FeatureFlag.ImprovedSchedulingUX);

  const dtnMode = useAtomValue(isDtnMode);

  const candidateActionVersion = useActionVersion(candidateId);
  const schedulingOwnership = useSchedulingOwnership(candidateId);
  const applicationId = useApplicationId(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const submitDecision = useSubmitDecision();
  const [queueEmail] = useQueueEmailMutation();

  const [proceedWithoutLink, setProceedWithoutLink] = useState(false);
  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [substages, setSubstages] = useState<BaseInterviewSubstage[]>([]);
  const [clientEmailTemplateId, setClientEmailTemplateId] = useState<string | null | undefined>(undefined);

  const { stage, setStage, stages, substage, isTakeHome, isUpdatingStage } = useStage({ candidateId });
  const [interviewDuration, setInterviewDuration] = useState<number | undefined>(undefined);
  const { interviewer, setInterviewer } = useInterviewer(candidateId, stage);

  // Fall back to what's defined on the stage if the user hasn't defined anything
  const stageSpecificSchedulingTemplateId = stage?.schedulingEmailTemplateId;
  const selectedEmailTemplateId = clientEmailTemplateId ?? stageSpecificSchedulingTemplateId;

  // whenever we switch stage should reset some state
  // in particular, reset client email template ID so it resets to stage specific default
  const setStageWrapper = (newStageId: string): void => {
    setClientEmailTemplateId(undefined);
    setStage(newStageId);
  };

  // Used to relocate the menu bar save as new template action outside of the editor
  const editorRef = useRef<CandidateActionEmailEditorRefType>(null);

  const interviewPanel = createInterviewPanel({
    interviewer,
    stage,
    substages,
    substageId: substage?.id,
    substageDuration: interviewDuration,
    schedulingOwnership,
    isTakeHome,
  });

  // NOTE: When scheduling after moving jobs, there's an issue where we invoke fetchEmailTemplate
  // before we rederive the candidate's desired `stage` via the `useStage` hook.
  // This hook involves a call to `listHiringPipelineStages` using the candidate's *new* job.
  // This causes `fetchEmailTemplate` to fail since it's using a HPS from the old job.
  // I'm not sure how to block on the resolution of the `HPS` call.
  const {
    to,
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey,
    threadId,
    isFetching,
    isValidatingLink,
    invalidLink,
    hasFindatimeLink,
    hasCalendlyLink,
    completedInterviews,
  } = useEmailState({
    candidateId,
    clientEmailTemplateId: selectedEmailTemplateId,
    decision: GetEmailTemplateRequestV2DecisionEnum.Approve,
    stage,
    interviewPanel,
    skipLinkValidation: dtnMode,
  });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate("Schedule", {
    fetchingTemplateAndBioData: isFetching,
    subject,
    to,
    from,
    body,
    desiredHiringPipelineStage: stage,
    substage,
    schedulingOwnership,
    interviewer,
    interviewPanel,
    isTakeHome,
    proceedWithoutLink,
    isValidatingLink,
    invalidLink,
    completedInterviews,
  });

  // Reset proceed without link if the interviewer changes
  useEffect(() => {
    setProceedWithoutLink(false);
    if (interviewer.value === DOVER_INTERVIEWER_ID) {
      setInterviewDuration(substage?.duration);
    }
  }, [interviewer, substage]);

  // set initial duration to substage duration
  useEffect(() => {
    setInterviewDuration(substage?.duration);
  }, [setInterviewDuration, substage]);

  // TODO: this can be the late stage check as well
  const usingMultipartEditor = isMultipartInterview(stage);
  const modalWidth = getModalWidth(stage);
  const showInterviewerPrefWarning =
    !isInterviewerEmpty(interviewer) && shouldDisableForInterviewerPreferences({ interviewer, proceedWithoutLink });

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = useCallback(async () => {
    if (!jobId || !stage || (!stage.id && !stage.isOneOffInterview) || !from) {
      return;
    }

    const emailArgs: CandidateActionEmailArgs = {
      emailAlias: from.id,
      toEmails: [to.email],
      ccEmails: cc.map(o => o.email),
      bccEmails: bcc.map(o => o.email),
      subject,
      body,
      threadId,
      messageKey: messageKey,
      sendAt: sendAt,
    };

    const args = {
      jobId,
      applicationId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Approve,
          auto: false,
          emailArgs,
          desiredHiringPipelineStage: stage?.id,
          interviewPanel,
          archiveReason: null,
        },
      },
    };

    let submit;
    // NOTE: When stage.id is undefined, we are scheduling a one-off interview. This is because one-off interviews
    // are not associated with a hiring pipeline stage. We can just queue an email which includes the scheduling link.
    setSubmitting(true);
    if (stage?.id) {
      submit = submitDecision(args).unwrap();
    } else {
      submit = queueEmail({
        id: candidateId,
        data: { candidateActionVersion, emailArgs },
      }).unwrap();
    }

    try {
      closeModal();

      await toast.promise(
        submit,
        {
          error: "Error updating candidate",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    } finally {
      setSubmitting(false);
    }
  }, [
    bcc,
    body,
    candidateActionVersion,
    candidateId,
    cc,
    closeModal,
    sendAt,
    from,
    interviewPanel,
    jobId,
    messageKey,
    stage,
    subject,
    submitDecision,
    queueEmail,
    threadId,
    to.email,
    applicationId,
    setSubmitting,
  ]);

  useHotkey(ENTER, "Schedule candidate", onSubmit);

  const ActionSection = useMemo(
    () => (
      <Stack width="100%" spacing={1}>
        {!isFetching && <WarningBanner warning={warning} />}
        {hasImprovedSchedulingUX && !isFetching && (
          <SchedulingLinkBanner bannerInfo={{ hasFindatimeLink, hasCalendlyLink }} />
        )}
        <Stack
          width="100%"
          spacing={2}
          direction="row"
          justifyContent="space-between"
          padding="12px"
          sx={{
            cursor: submitting ? "not-allowed" : "pointer",
            opacity: submitting ? 0.5 : 1,
          }}
        >
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Body>Send </Body>
            <ScheduledActionButton setActionAt={setSendAt} />
          </Stack>
          <Stack direction="row" spacing={2}>
            {hasImprovedSchedulingUX && (
              <Button
                variant={ButtonVariant.SecondaryLight}
                onClick={(): void => {
                  if (editorRef.current?.handleSaveAsNewTemplate) {
                    editorRef.current?.handleSaveAsNewTemplate();
                  }
                }}
                disabled={submitting || disabledMap.Send}
              >
                Save as template
              </Button>
            )}
            <Button
              variant={ButtonVariant.SecondarySuccess}
              onClick={onSubmit}
              disabled={submitting || disabledMap.Send}
              tooltip={tooltip}
            >
              {isUpdatingStage && !dtnMode ? "Send and update stage" : "Send"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      disabledMap.Send,
      setSendAt,
      isFetching,
      isUpdatingStage,
      onSubmit,
      tooltip,
      warning,
      dtnMode,
      submitting,
      hasCalendlyLink,
      hasFindatimeLink,
      hasImprovedSchedulingUX,
      editorRef,
    ]
  );

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Schedule</Body>}
        maxWidth={modalWidth}
        customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
        customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
        dialogActions={ActionSection}
        dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
      >
        <Stack spacing={2}>
          {usingMultipartEditor ? (
            <Stack spacing={2} direction="column">
              <StageSelect stageId={stage?.id} setStage={setStageWrapper} stages={stages} />
              <InterviewerSelect
                candidateId={candidateId}
                desiredHiringPipelineStage={stage}
                interviewer={interviewer}
                setInterviewer={setInterviewer}
                setSubstages={setSubstages}
                proceedWithoutLink={proceedWithoutLink}
                isTakeHome={isTakeHome}
              />
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <StageSelect
                  stageId={stage?.id}
                  setStage={setStageWrapper}
                  stages={stages}
                  disabled={dtnMode}
                  disabledTooltipText={
                    dtnMode
                      ? "Dover Talent Network candidates can only be approved to the initial call stage"
                      : undefined
                  }
                />
                <InterviewerSelect
                  candidateId={candidateId}
                  desiredHiringPipelineStage={stage}
                  interviewer={interviewer}
                  setInterviewer={setInterviewer}
                  setSubstages={setSubstages}
                  proceedWithoutLink={proceedWithoutLink}
                  isTakeHome={isTakeHome}
                />
                <DurationSelect
                  disabled={interviewer.value === DOVER_INTERVIEWER_ID || isTakeHome}
                  duration={interviewDuration}
                  disabledTooltipText={
                    interviewer.value === DOVER_INTERVIEWER_ID
                      ? "Duration cannot be edited for Dover Interviews"
                      : isTakeHome
                      ? "Duration not applicable for Assessment / Non-interview stages"
                      : undefined
                  }
                  setDuration={(e: SelectChangeEvent<number | unknown>): void =>
                    setInterviewDuration(e.target.value as number)
                  }
                />
              </Stack>
              {showInterviewerPrefWarning && (
                <Banner variant={BannerVariant.Warning}>
                  <Stack spacing={1}>
                    <BodySmall>
                      {interviewer.fullName} must complete their{" "}
                      <a href={"settings/interviews"} rel="noopener noreferrer" target="_blank">
                        interview preferences
                      </a>{" "}
                      in order to autofill a scheduling link.
                    </BodySmall>
                    <Button
                      variant={ButtonVariant.Secondary}
                      onClick={(): void => setProceedWithoutLink(true)}
                      width="max-content"
                    >
                      <BodySmall>Proceed without scheduling link</BodySmall>
                    </Button>
                  </Stack>
                </Banner>
              )}
            </Stack>
          )}
          <ClientEmailTemplateSelect
            disabled={dtnMode}
            disabledTooltipText={dtnMode ? "Dover Talent Network scheduling emails cannot be customized" : undefined}
            clientEmailTemplateId={selectedEmailTemplateId}
            setClientEmailTemplateId={setClientEmailTemplateId}
          />
          {!hideEditor && (
            <>
              <Divider
                sx={{
                  margin: "24px -24px !important",
                }}
              />
              <Stack spacing={1}>
                <BodySmall weight="500">Email</BodySmall>
                <CandidateActionEmailEditor
                  candidateId={candidateId}
                  interviewer={interviewer}
                  desiredHiringPipelineStageId={stage?.isOneOffInterview ? undefined : stage?.id}
                  duration={interviewDuration}
                  hideSchedulingLink={proceedWithoutLink || usingMultipartEditor}
                  body={initialBody}
                  onBodyChanged={setBody}
                  subject={initialSubject}
                  onSubjectChanged={setSubject}
                  from={from}
                  onFromChanged={setFrom}
                  to={to}
                  cc={cc}
                  onCcChanged={setCc}
                  bcc={bcc}
                  onBccChanged={setBcc}
                  disableFrom={disabledMap.From}
                  disableTo={disabledMap.To}
                  disableCc={disabledMap.CC}
                  disableBcc={disabledMap.BCC}
                  disableSubject={disabledMap.Subject || dtnMode}
                  disableBody={disabledMap.Body || dtnMode}
                  loading={isFetching}
                  hideSaveTemplateButton={hasImprovedSchedulingUX}
                  ref={editorRef}
                />
              </Stack>
            </>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};

export default SchedulingModal;
