import { skipToken } from "@reduxjs/toolkit/dist/query";
import clipboardCopy from "clipboard-copy";
import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import WarningIconSVG from "assets/icons/alert.svg";
import CopyIcon from "assets/icons/copy.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useNextInterviewer } from "components/NextInterviewer/hooks";
import { appConfig } from "config/appConfig";
import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";

export const useInvalidInterviewerTooltipText = ({
  candidateId,
}: {
  candidateId?: string;
}): string | React.ReactElement | undefined => {
  const [clicked, setClicked] = React.useState(false);

  const { data: candidateBio, isFetching: isFetchingCandidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const { nextInterviewer, nextInterviewerValid } = useNextInterviewer(candidateBio, candidateBio?.schedulingOwnership);

  if (nextInterviewerValid || isFetchingCandidateBio) {
    return undefined;
  }

  if (nextInterviewer) {
    return (
      <>
        Unable to approve candidate - {nextInterviewer.fullName} has not filled out their{" "}
        <InternalLink to="/settings/interviews">interview preferences</InternalLink> on Dover. Please ask them to fill
        it out or choose another interviewer. (
        <Tooltip
          variant={TooltipVariant.Dark}
          title={clicked ? "Copied!" : "Copy to clipboard"}
          placement="top"
          onOpen={(): void => setClicked(false)}
          onClick={(): void => {
            clipboardCopy(`${appConfig.appUrl}/settings/interviews`);
            setClicked(true);
          }}
        >
          <FakeLink>
            copy to clipboard <StyledReactSVG src={CopyIcon} />
          </FakeLink>
        </Tooltip>
        )
      </>
    );
  }

  return <>Unable to approve candidate - please select an interviewer.</>;
};

export const ValidateNextInterviewer = ({
  showAsTooltip,
  candidateIdOverride,
}: {
  showAsTooltip?: boolean;
  candidateIdOverride?: string;
}): React.ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio, isFetching: isFetchingCandidateBio } = useGetCandidateBioQuery(
    candidateIdOverride ?? candidateId ?? skipToken
  );
  const disabledTooltip = useInvalidInterviewerTooltipText({ candidateId: candidateBio?.id });

  const warningComponent = useMemo((): React.ReactElement | undefined => {
    if (disabledTooltip) {
      return (
        <StyledWarningContainer showAsTooltip={showAsTooltip}>
          <StyledWarningIcon src={WarningIconSVG} style={{ display: "inline" }} />
          {disabledTooltip}
        </StyledWarningContainer>
      );
    }
    return undefined;
  }, [disabledTooltip, showAsTooltip]);

  if (isFetchingCandidateBio || !candidateBio || !warningComponent) {
    return <></>;
  }

  if (showAsTooltip) {
    return (
      <Tooltip variant={TooltipVariant.Light} title={warningComponent}>
        <div>
          <StyledWarningIcon src={WarningIconSVG} style={{ display: "inline" }} />
        </div>
      </Tooltip>
    );
  }

  return warningComponent;
};

interface WarningContainerProps {
  showAsTooltip?: boolean;
}

const StyledWarningContainer = styled(BodySmall)<WarningContainerProps>`
  border-top: ${(props): string => (props.showAsTooltip ? "" : `solid 1px ${colors.grayscale.gray300}`)};
  padding: 15px;
  margin-top: ${(props): string => (!props.showAsTooltip ? "8px" : "")};
`;

const StyledWarningIcon = styled(ReactSVG)`
  display: inline-block;
  position: relative;
  bottom: 2px;
  margin-right: 3px;
`;

const FakeLink = styled.span`
  color: ${colors.link};
  cursor: pointer;
`;

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  margin-right: 2px;
  path {
    stroke: ${colors.link};
  }
`;
