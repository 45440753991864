import styled from "styled-components";

import { colors } from "styles/theme";

/**
 * @color Color of text, often supplied with a color value from our theme
 * @centered Sets text align to center
 * @inline Sets display to inline
 * @weight Overrides default font weight, must be one of 100, 400, 500, 600, 700
 * @italic Sets the font style to italic
 * @strikethrough Sets text decoration to line-through
 * @ellipsis Sets text-overflow, overflow, and whitespace to allow cutting off text with an ellipsis when it overflows.
 * For this to work you will need to place this text inside a container that has a restricted width.
 */
export interface BaseTypeProps {
  color?: string;
  centered?: boolean;
  inline?: boolean;
  weight?: "100" | "300" | "400" | "500" | "600" | "700" | "800";
  italic?: boolean;
  textDecoration?: "underline" | "line-through";
  ellipsis?: boolean;
  pointer?: boolean;
  noWrap?: boolean;
}

const BaseType = styled.div<BaseTypeProps>`
  font-family: Inter;
  color: ${(props): string => props.color ?? colors.black};
  text-align: ${(props): string | undefined => (props.centered ? "center" : undefined)};
  display: ${(props): string | undefined => (props.inline ? "inline" : undefined)};
  font-weight: ${(props): string | undefined => (props.weight ? `${props.weight} !important` : undefined)};
  text-decoration: ${(props): string | undefined => props.textDecoration};
  font-style: ${(props): string | undefined => (props.italic ? "italic" : undefined)};
  text-overflow: ${(props): string | undefined => (props.ellipsis ? "ellipsis" : undefined)};
  overflow: ${(props): string | undefined => (props.ellipsis ? "hidden" : undefined)};
  white-space: ${(props): string | undefined => (props.ellipsis || props.noWrap ? "nowrap" : undefined)};
  cursor: ${(props): string | undefined => (props.pointer ? "pointer" : undefined)};
`;

export const TitleExtraLarge = styled(BaseType)`
  font-weight: 600;
  font-size: 43px;
  line-height: 52px;
`;

export const TitleLarge = styled(BaseType)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

export const PageTitle = styled(BaseType)`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
`;

export const Heading = styled(BaseType)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const Subtitle1 = styled(BaseType)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const Subtitle2 = styled(BaseType)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const Body = styled(BaseType)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const BodySmall = styled(BaseType)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const BodyExtraSmall = styled(BaseType)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const ButtonText = styled(BaseType)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const Caption = styled(BaseType)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const Overline = styled(BaseType)`
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const CenteredOverline = styled(Overline)`
  text-align: center;
  padding-top: 2px;
`;
