import { Box } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React, { useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import CopyIcon from "assets/icons/copy.svg";
import { Banner, BannerVariant } from "components/library/Banner";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import {
  CandidateBioSchedulingOwnershipEnum,
  ClientInterviewer,
  ClientInterviewerSetupStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";

export const ValidateInterviewers = ({
  selectedInterviewerIds,
  setSelectedInterviewersValid,
  selectedInterviewersValid,
  scheduler,
}: {
  selectedInterviewerIds: string[];
  setSelectedInterviewersValid: (valid: boolean) => void;
  selectedInterviewersValid: boolean;
  scheduler?: CandidateBioSchedulingOwnershipEnum;
}): React.ReactElement => {
  const [clicked, setClicked] = React.useState(false);

  const allInterviewers = useListInterviewerEntities();

  const interviewers = useMemo(() => {
    return allInterviewers.filter((i: any) => selectedInterviewerIds.includes(i.id));
  }, [selectedInterviewerIds, allInterviewers]);

  const interviewerValid = (interviewer: ClientInterviewer): boolean => {
    return (
      interviewer.id === "DOVER_INTERVIEWER" ||
      interviewer.id === "DOVER_INTERVIEWER_ID" ||
      interviewer.setupState === ClientInterviewerSetupStateEnum.Ready ||
      (interviewer?.setupState === ClientInterviewerSetupStateEnum.NeedsAuth &&
        !!interviewer?.hasSchedulingLinkOverride)
    );
  };

  const invalidInterviewers = useMemo(() => {
    return interviewers.filter(interviewer => !interviewerValid(interviewer));
  }, [interviewers]);

  useEffect(() => {
    setSelectedInterviewersValid(
      scheduler === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling || invalidInterviewers.length === 0
    );
  }, [invalidInterviewers.length, scheduler, setSelectedInterviewersValid]);

  if (selectedInterviewersValid || !invalidInterviewers.length) {
    return <></>;
  }

  return (
    <Banner variant={BannerVariant.Warning}>
      <Box>
        <Subtitle2>Interviewer preferences incomplete</Subtitle2>
        <BodySmall>
          {invalidInterviewers.map(
            (interviewer, index): React.ReactElement => (
              <>
                {interviewer.fullName ?? ""}
                {index === invalidInterviewers.length - 1 ? "" : ", "}
              </>
            )
          )}{" "}
          {invalidInterviewers.length > 1 ? "have" : "has"} not filled out their{" "}
          <InternalLink to="/settings/interviews">interview preferences</InternalLink> on Dover. Please ask them to fill
          it out or choose another interviewer. (
          <Tooltip
            variant={TooltipVariant.Dark}
            title={clicked ? "Copied!" : "Copy to clipboard"}
            placement="top"
            onOpen={(): void => setClicked(false)}
            onClick={(): void => {
              clipboardCopy(`${appConfig.appUrl}/settings/interviews`);
              setClicked(true);
            }}
          >
            <FakeLink>
              copy to clipboard <StyledReactSVG src={CopyIcon} />
            </FakeLink>
          </Tooltip>
          )
        </BodySmall>
      </Box>
    </Banner>
  );
};

const FakeLink = styled.span`
  color: ${colors.link};
  cursor: pointer;
`;

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  margin-right: 2px;
  path {
    stroke: ${colors.link};
  }
`;
