import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import GrowYourTeamImage from "assets/images/grow_your_team.png";
import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";

export const NoJobsCard = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" spacing={3}>
      <Box p={3} width="450px" maxWidth="450px">
        <img src={GrowYourTeamImage} style={{ width: "100%" }} />
      </Box>
      <Stack justifyContent="center" p={3} maxWidth="720px">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Body weight="600">Grow your team with Dover</Body>
            <Body>
              Welcome to Dover, your one-stop shop for hiring! Get started by creating a job and explore our free ATS
              and sourcing tools.
            </Body>
            <Body>
              When you&apos;re ready, pull in a Recruiting Partner to streamline everything from sourcing to logistics,
              making hiring effortless.
            </Body>
          </Stack>
          <Stack direction="row" spacing={2}>
            <CreateJobButton omitCreateJobModal buttonProps={{ variant: ButtonVariant.Primary }} />
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => navigate(APP_ROUTE_PATHS.recruitingPartners())}
            >
              View Recruiting Partners
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
