import { Box, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { BooleanParam, createEnumParam, StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { CREATE_JOB_DRAWER_OPEN_PARAM, CREATE_JOB_CONTEXT_PARAM } from "components/dover/CreateJob/constants";
import SourcingSelectionModal from "components/dover/CreateJob/SourcingSelection";
import { Button, ButtonProps, ButtonVariant } from "components/library/Button";
import { Subtitle1 } from "components/library/typography";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi/models/CreateNewJobRequest";
import { colors } from "styles/theme";
import CreateJob from "views/create-job/CreateJob";

const CreateJobButton = ({
  buttonContent,
  buttonProps = { variant: ButtonVariant.Ghost },
  triggerElement,
  context = CreateNewJobRequestCreateJobContextEnum.Default,
  label = "Add a Job",
  // reason for this variable is that this component (which works as a trigger) may live in a dropdown which closes after being clicked
  // after it gets closed, the actual createjob modal will close because the dropdown/trigger is no longer rendered
  // so use this variable to omit the actual createjob modal, and use it somewhere else in the file where the trigger exists
  omitCreateJobModal,
  includePlusIcon = false,
}: {
  buttonContent?: ReactElement | string;
  buttonProps?: ButtonProps;
  triggerElement?: ReactElement;
  context?: CreateNewJobRequestCreateJobContextEnum;
  label?: string;
  omitCreateJobModal?: boolean;
  includePlusIcon?: boolean;
}): React.ReactElement => {
  const doverPlan = useGetDoverPlan();
  const [, setStepParam] = useQueryParam("step", StringParam);
  const [, setCreateJobDrawerOpen] = useQueryParam(CREATE_JOB_DRAWER_OPEN_PARAM, BooleanParam);
  const [, setContext] = useQueryParam(
    CREATE_JOB_CONTEXT_PARAM,
    createEnumParam(Object.values(CreateNewJobRequestCreateJobContextEnum))
  );
  const [sourcingSelectionModalOpen, setSourcingSelectionModalOpen] = React.useState(false);

  const onClickCreateJob = React.useCallback(() => {
    setContext(context);
    setCreateJobDrawerOpen(true);
    // Reset step param to start on first step
    setStepParam(undefined);
  }, [setContext, context, setCreateJobDrawerOpen, setStepParam]);

  return (
    <>
      <SourcingSelectionModal
        isOpen={sourcingSelectionModalOpen}
        close={(): void => setSourcingSelectionModalOpen(false)}
      />
      {omitCreateJobModal ? <></> : <CreateJob />}
      {triggerElement ? (
        <Box onClick={onClickCreateJob}>{triggerElement}</Box>
      ) : (
        <Button {...buttonProps} onClick={onClickCreateJob} disabled={!doverPlan}>
          <Stack spacing={0.5} direction="row" alignItems="center">
            {includePlusIcon && <PlusIcon />}
            {buttonContent ? (
              buttonContent
            ) : (
              <Subtitle1 style={{ fontWeight: "400" }} color={colors.white}>
                {label}
              </Subtitle1>
            )}
          </Stack>
        </Button>
      )}
    </>
  );
};

export default CreateJobButton;
