import React, { useEffect } from "react";

interface ThirdPartyCookieSupportCheckerProps {
  setThirdPartyCookieSupportEnabled: (enabled: boolean | null) => void;
}

const ThirdPartyCookieSupportChecker: React.FC<ThirdPartyCookieSupportCheckerProps> = ({
  setThirdPartyCookieSupportEnabled,
}) => {
  // this is a gcp bucket hosted version of utils/third_party_cookie_checker/thirdPartyCookieCheckerIFrame.html
  // this is hosted in gcp as the contents need to be on another domain.react
  const THIRD_PARTY_COOKIES_CHECKER_IFRAME_URL =
    "https://storage.googleapis.com/dover-public/thirdPartyCookieCheckerIFrame.html";
  // set to local url for quick iteration while testing
  // const THIRD_PARTY_COOKIES_CHECKER_IFRAME_URL = "http://127.0.0.1:8000/utils/third-party-cookie-checker/iframe";
  const [iframeLoaded, setIframeLoaded] = React.useState<boolean>(false);
  useEffect(() => {
    const frame = document.getElementById("thirdPartyCookieCheckerIFrame");

    const contentWindow = (frame as HTMLIFrameElement)?.contentWindow;
    if (!contentWindow) {
      return;
    }
    console.log("Sending message to third party cookie checker iframe");
    contentWindow.postMessage(JSON.stringify({ test: "doverCookieCheck" }), "*");

    const messageHandler = (event: MessageEvent): void => {
      try {
        console.log("event received:", event.data, event);
        if (event.source !== contentWindow) {
          if (event.data?.source.startsWith("react-dev")) {
            return;
          }
          console.log("Ignoring message from different source");
          return;
        }
        const data = JSON.parse(event.data);
        console.log("Third party cookie checker message received:", data);
        setThirdPartyCookieSupportEnabled(data["result"]);
      } catch {
        // if we got json errors this is probably a message from some other frame
        return;
      }
    };
    window.addEventListener("message", messageHandler);

    return (): void => {
      window.removeEventListener("message", messageHandler);
    };
  }, [setThirdPartyCookieSupportEnabled, iframeLoaded]);

  return (
    <>
      <iframe
        id="thirdPartyCookieCheckerIFrame"
        style={{ display: "none" }}
        sandbox="allow-scripts allow-same-origin"
        src={THIRD_PARTY_COOKIES_CHECKER_IFRAME_URL}
        onLoad={(): void => setIframeLoaded(true)}
      ></iframe>
    </>
  );
};

export default ThirdPartyCookieSupportChecker;
