import { Progress } from "@doverhq/dover-ui";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { MouseEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { FULL_SCREEN_EXCEPT_TOP_BAR_HEIGHT } from "App/appConstants";
import { CandidateHeader } from "components/CandidateHeader/CandidateHeader";
import { Centered } from "components/Centered";
import PageHelmet from "components/PageHelmet";
import { Role, useContainsRole } from "components/RBAC";
import { candidatesReducerKey, candidatesReducer, candidatesActions } from "domains/candidate/reducer";
import { useWindowDimensions } from "hooks";
import { useCandidateId } from "hooks/useCandidateId";
import { useCandidateIsOfferOrHired } from "hooks/useCandidateIsOfferOrHired";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetAuthedUserInfoQuery, usePrefetch } from "services/doverapi/endpoints/proUser";
import { colors } from "styles/theme";
import { useInjectReducer } from "utils/injectReducer";
import { Bio } from "views/CandidateDetail/components/bio/Bio";
import { CandidateActivity } from "views/CandidateDetail/components/CandidateActivity";
import { CandidateBio } from "views/CandidateDetail/components/CandidateBio";
import { EventFeed } from "views/CandidateDetail/components/EventFeed";
import { NavigationArrows } from "views/CandidateDetail/NavigationArrows";
import { CloseFunction } from "views/CandidateDetail/types";
import RestrictedAccess from "views/RestrictedAccess";

interface Props {
  close?: CloseFunction;
}

const CandidateDetailView = ({ close }: Props): React.ReactElement => {
  useInjectReducer({ key: candidatesReducerKey, reducer: candidatesReducer });

  const { isSuccess: userInfoInitialized } = useGetAuthedUserInfoQuery();

  const prefetchProUsers = usePrefetch("listProUsersForClient");

  useEffect(() => {
    // prefetch proUsers for candidate notes editor so at-mentions are snappy
    prefetchProUsers({});
  }, [prefetchProUsers]);

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const candidateId = useCandidateId();

  const { data: candidateBio, isFetching: isFetchingCandidateBio, error } = useGetCandidateBioQuery(
    candidateId ?? skipToken
  );

  const isLoading = !candidateBio || isFetchingCandidateBio;

  // When the candidate is in offer or hired, only the job hiring manager can see their activity/interview notes
  const allowedRoles = [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER];
  const userCanViewDetailsAlways = useContainsRole(allowedRoles, candidateBio?.job);
  const isHiredOrOffer = useCandidateIsOfferOrHired(candidateBio);
  const shouldHideCandidateDetails = isHiredOrOffer && !userCanViewDetailsAlways;

  useEffect(() => {
    dispatch(candidatesActions.setCurrentCandidate(candidateId));
  }, [candidateId, dispatch]);

  if (!userInfoInitialized) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  const candidateName = candidateBio?.contact?.fullName;
  const jobName = candidateBio?.jobTitle;

  // we should probably fix this responsive view or remove it. This code duplication is hard to maintain
  if (width && width <= 566) {
    return (
      <>
        <PageHelmet title={`${candidateName || ""} - ${jobName || ""}`} />

        <PageContainer
          sx={{
            maxHeight: "calc(100vh)",
            maxWidth: "566px",
            overflow: "auto",
            position: "relative",
          }}
          onClick={(): void => close && close(candidateBio!)}
        >
          {candidateBio && !isFetchingCandidateBio && (
            <Box onClick={(e): void => e.stopPropagation()}>
              <Box sx={{ position: "absolute", top: "4px", right: "4px", "&:hover": { cursor: "pointer" } }}>
                <CloseIcon color="action" onClick={(): void => close && close(candidateBio)} />
              </Box>
              <CandidateHeader candidateBio={candidateBio} />
              <Bio />
              <EventFeed
                pipelineStage={candidateBio.candidatePipelineStage}
                shouldHideCandidateDetails={shouldHideCandidateDetails}
                isHiredOrOffer={isHiredOrOffer}
              />
            </Box>
          )}
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <PageHelmet title={`${candidateName || ""} - ${jobName || ""}`} />

      <PageContainer
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingY: "32px",
          maxHeight: FULL_SCREEN_EXCEPT_TOP_BAR_HEIGHT,
        }}
        onClick={(): void => close && close(candidateBio!)}
      >
        <NavigationArrows
          key={candidateBio?.id!}
          jobId={candidateBio?.job!}
          candidateId={candidateBio?.id!}
          isLoading={isLoading}
        />
        <CandidateDetailsView
          onClick={(e: MouseEvent<HTMLDivElement | MouseEvent>): void => {
            e.stopPropagation();
          }}
        >
          {!candidateBio || isFetchingCandidateBio ? (
            <Box sx={{ minWidth: "908px", maxWidth: "1110px", height: "100%", position: "relative" }}>
              <Centered>
                {/* @ts-ignore */}
                {error?.serializedError?.status === 404 ? <RestrictedAccess /> : <Progress />}
              </Centered>
            </Box>
          ) : (
            <Content sx={{ display: "flex", height: "100%" }}>
              <CandidateActivityWrapper
                className="candidate-activity-wrapper"
                sx={{
                  flex: "1 0 auto",
                  height: "100%",
                  width: "768px",
                  borderRight: `1px solid ${colors.grayscale.gray200}`,
                }}
              >
                <CandidateActivity
                  shouldHideCandidateDetails={shouldHideCandidateDetails}
                  isHiredOrOffer={isHiredOrOffer}
                />
              </CandidateActivityWrapper>
              <CandidateBioWrapper
                className="candidate-bio-wrapper"
                sx={{
                  flex: "0 0 342px",
                }}
              >
                <CandidateBio close={close} />
              </CandidateBioWrapper>
            </Content>
          )}
        </CandidateDetailsView>
      </PageContainer>
    </>
  );
};

const PageContainer = Box;
const Content = Box;
const CandidateActivityWrapper = Box;
const CandidateBioWrapper = Box;
const CandidateDetailsView = styled.div`
  border: 1px solid ${colors.grayscale.gray200};
  background: ${colors.grayscale.gray200};
  border-radius: 8px;
  max-width: 1110px;
  position: relative;
  height: ${FULL_SCREEN_EXCEPT_TOP_BAR_HEIGHT};
  overflow: hidden;
`;

export default CandidateDetailView;
