import { Skeleton, Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { FC, useEffect } from "react";

import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { Body } from "components/library/typography";
import { PipelineCandidate } from "services/openapi";
import { addPageToStageAtom } from "views/candidates/CandidateNavigation/atoms";
import { addCandidateToMapAtom } from "views/candidates/CandidateTable/board/atoms";
import { DraggableCandidateCard, PreviewCandidateCard } from "views/candidates/CandidateTable/board/components/Card";
import { useBoardCandidates } from "views/candidates/CandidateTable/board/hooks";
import { CANDIDATE_CARD_HEIGHT } from "views/candidates/CandidateTable/board/styles";
import { useListCandidatesWithNextActions, useParams } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";

interface PageProps {
  stageId: string;
  page: number; // Signifies which page number this page is for
  count: number; // Signifies how many candidates will be on this page
  showMatchLabel?: boolean;
  expand?: string;
  previewCandidate?: PipelineCandidate;
}

const Page: FC<React.PropsWithChildren<PageProps>> = ({
  stageId,
  page,
  count,
  showMatchLabel,
  expand,
  previewCandidate,
}) => {
  const [{ quickFilter }] = useParams();
  const rejectedQuickFilterOn = quickFilter === QuickFilterEnum.Rejected;

  const addCandidateToMap = useSetAtom(addCandidateToMapAtom);

  const { data, isFetching, isError } = useBoardCandidates({
    stageId,
    page,
    isApplicationStage: showMatchLabel,
    expand,
  });

  // we only need to determine next actions for
  const [initialCandidateIds, setInitialCandidateIds] = React.useState<string[] | undefined>(undefined);
  const candidateIds = data?.results.map(c => c.id!);
  if (candidateIds && !initialCandidateIds) {
    setInitialCandidateIds(candidateIds);
  }

  const nextActionArgs = showMatchLabel || rejectedQuickFilterOn ? undefined : initialCandidateIds;
  const { data: candidatesWithNextActions, isFetching: isNaFetching } = useListCandidatesWithNextActions(
    nextActionArgs
  );

  // Add each candidate to the map for access higher up in the tree (I hate this)
  const addPageToStage = useSetAtom(addPageToStageAtom);
  useEffect((): void => {
    if (data?.results) {
      data.results.forEach(c => {
        addCandidateToMap(c);
      });
      addPageToStage({ stageId, page, candidates: data.results });
    }
  }, [page, stageId, data, addCandidateToMap, addPageToStage]);

  if (isFetching) {
    return (
      <>
        {[...Array(Math.min(count, 9))].map(() => (
          <Skeleton variant="rounded" height={CANDIDATE_CARD_HEIGHT} width="100%" />
        ))}
      </>
    );
  }

  if (!data || isError) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error while fetching candidate data.</Body>
      </Stack>
    );
  }

  return (
    <>
      {previewCandidate && <PreviewCandidateCard candidate={previewCandidate} />}
      {data?.results.map(c => (
        <DraggableCandidateCard
          key={c.id}
          candidate={c}
          page={page}
          nextAction={candidatesWithNextActions?.[c.id!]}
          nextActionLoading={isNaFetching}
          showMatchLabel={showMatchLabel}
        />
      ))}
    </>
  );
};

export default Page;
