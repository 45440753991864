import { Button } from "@doverhq/dover-ui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation, useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import {
  getNextCandidateAtom,
  getPrevCandidateAtom,
  useFetchNextPage,
} from "views/candidates/CandidateNavigation/atoms";

const PREV_HOT_KEY = "left";
const NEXT_HOT_KEY = "right";

interface NavigationArrowsProps {
  jobId: string;
  candidateId: string;
  isLoading: boolean;
}

export const NavigationArrows = React.memo(
  ({ jobId, candidateId, isLoading }: NavigationArrowsProps): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const isReviewPage = location.pathname.startsWith("/review/");

    const handleNavigation = useCallback(
      (candidateId?: string, page?: number): void => {
        const params = new URLSearchParams(location.search);
        if (page !== undefined) {
          params.set("page", page.toString());
        }

        if (isReviewPage) {
          navigate(`${APP_ROUTE_PATHS.review({ candidateId, jobId, queryParams: params })}`);
          return;
        }

        if (candidateId) {
          navigate(`${APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, candidateId, params)}`);
        } else {
          navigate(`${APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId, params)}`);
        }
      },
      [isReviewPage, jobId, location.search, navigate]
    );

    const getPrevCandidate = useAtomValue(getPrevCandidateAtom);
    const prevCandidate = getPrevCandidate(candidateId);
    const toPrevCandidate = useCallback((): void => {
      handleNavigation(prevCandidate?.id, prevCandidate?.page);
    }, [handleNavigation, prevCandidate]);

    const getNextCandidate = useAtomValue(getNextCandidateAtom);
    const nextCandidate = getNextCandidate(candidateId);
    const fetchNextPage = useFetchNextPage(nextCandidate?.stageId, nextCandidate?.page);

    const toNextCandidate = useCallback((): void => {
      fetchNextPage?.();
      const nextCandidate = getNextCandidate(candidateId);
      handleNavigation(nextCandidate?.id, nextCandidate?.page);
    }, [candidateId, fetchNextPage, handleNavigation, getNextCandidate]);

    useHotkeys(PREV_HOT_KEY, toPrevCandidate, { enabled: !isLoading }, [toPrevCandidate, isLoading]);
    useHotkeys(NEXT_HOT_KEY, toNextCandidate, { enabled: !isLoading }, [toNextCandidate, isLoading]);

    return (
      <Box>
        <Stack alignItems="center" spacing={2} marginRight="1.25em">
          {prevCandidate && (
            <Button variant="primaryOutlined" p={0.5} onPress={toPrevCandidate} isDisabled={isLoading}>
              <ExpandLess color={isLoading ? "disabled" : "action"} />
            </Button>
          )}

          <Button variant="primaryOutlined" p={0.5} onPress={toNextCandidate} isDisabled={isLoading}>
            <ExpandMore color={isLoading ? "disabled" : "action"} />
          </Button>
        </Stack>
      </Box>
    );
  }
);
